<template>
<div class="row-space-tbf" v-if="loaded">
	<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="$router.push({ name: 'tickets' })"><icon-arrow-back /></div></div>
    <div class="content opacity-page">
        <div class="header-form">
            <h1 class="title">{{ $route.params.id ? $t('tickets.title_edit') : $t('tickets.title_new')}}</h1>
            <div class="actions">
                <button class="btn-tbf white only-icon" @click="$router.push({ name: 'tickets' })"><icon-close class="icon-close" /></button>
            </div>
		</div>
        <div class="content tickets">

        <div class="form-group tickets" :class="{empty: users.length == 0}">
            <div class="form">
                <div class="input-group" :class="$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-25'">
                    <div class="label-header">
                        <label class="label">{{ $t('tickets.ticket_type') }}*</label>
                    </div>
                    <div class="input-box">
                        <div class="icon-left"><icon-ticket /></div>
                        <multiselect 
                            v-model="selectedTicket"
                            class="select-tags-tbf"
                            :options="tickets"
                            :allow-empty="true"
                            :show-labels="false"
                            track-by="id" 
                            label="name"
                            @input="getUsers()"
                            >
                            <template slot="placeholder" slot-scope="props">
                                <span class="text">
                                    {{ $t('tickets.ticket_type-ph') }}
                                </span>
                            </template>
                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                        </multiselect>
                    </div>
                </div>
            </div>
            <div v-if="users.length > 0" class="form" v-for="(user, index) in users" :key="'user'+index">
                <div class="input-group w-10">
                    <div class="label-header">
                        <label v-if="index == 0" class="label">{{ $t('general.user-name') }}</label>
                    </div>
                    <div class="text-name">
                        <v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
                                <div class="text">
                                   <div class="circle-status" v-bind:class="{active: user.id != null}"></div>
                                </div>
                                <template slot="popover">
                                    <div class="simple-text">{{ user.id != null ? $t('tickets.active') : $t('tickets.inactive') }}</div>
                                </template>
                            </v-popover>
                        {{user.first_name + ' ' + user.last_name}}
                    </div>
                </div>
                <div class="input-group w-20">
                    <div class="label-header">
                        <label v-if="index == 0" class="label">{{ $t('tickets.name') }}</label>
                    </div>
                    <div class="input-box" v-bind:class="{has_error: user.name === ''}">
                        <input type="text" class="input-text no-icon" v-model="user.name">
                    </div>
                </div>
                <div class="input-group w-10">
                    <div class="label-header">
                        <label v-if="index == 0" class="label">{{ $t('tickets.number') }}</label>
                    </div>
                    <div class="input-box" v-bind:class="{has_error: user.number === ''}">
                        <input @input="user.total_amount = (user.number-user.returned)*user.nominal_value" onkeypress="return event.charCode >= 48 && event.charCode <= 57" type="number" class="input-text no-icon" v-model.number="user.number">
                    </div>
                </div>
                <div class="input-group w-10">
                    <div class="label-header">
                        <label v-if="index == 0" class="label">{{ $t('tickets.returned') }}</label>
                    </div>
                    <div class="input-box" v-bind:class="{has_error: user.returned === ''}">
                        <input @input="user.total_amount = (user.number-user.returned)*user.nominal_value" type="number" onkeypress="return event.charCode >= 48 && event.charCode <= 57" class="input-text no-icon" v-model.number="user.returned">
                    </div>
                </div>
                 <div class="input-group w-10">
                    <div class="label-header">
                        <label v-if="index == 0" class="label">{{ $t('tickets.value') }}</label>
                    </div>
                    <div class="input-box" v-bind:class="{has_error: user.nominal_value === ''}">
                        <input @input="user.total_amount = (user.number-user.returned)*user.nominal_value" min="0" value="0" step=".01" pattern="^\d*(\.\d{0,2})?$" type="number" class="input-text no-icon" v-model="user.nominal_value">
                    </div>
                </div>
                 <div class="input-group w-10">
                    <div class="label-header">
                        <label v-if="index == 0" class="label">{{ $t('tickets.total') }}</label>
                    </div>
                    <div class="input-box bg-gray">
                        <input disabled type="text" class="input-text no-icon" :value="Math.round(user.total_amount)" >
                    </div>
                </div>
                <div class="input-group w-10">
                    <div class="label-header">
                        <label v-if="index == 0" class="label">{{ $t('tickets.taxable') }}</label>
                    </div>
                    <div class="input-box" v-bind:class="{'bg-gray': !selectedTicket.is_taxable_type_editable}">
                        <multiselect 
                            :value="retainOptions.find(el => el.id == user.taxable_type)"
                            class="select-tags-tbf ticket "
                            :options="retainOptions"
                            :allow-empty="true"
                            :show-labels="false"
                            track-by="id" 
                            label="name"
                            :disabled="!selectedTicket.is_taxable_type_editable"
                            @input="user.taxable_type = $event.id"
                            >
                            <template slot="placeholder" slot-scope="props">
                                <span class="text">
                                    {{ $t('tickets.ticket_type-ph') }}
                                </span>
                            </template>
                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                        </multiselect>
                    </div>
                </div>
                <div class="input-group w-10">
                    <div class="label-header">
                        <label v-if="index == 0" class="label">{{ $t('tickets.retain_cas') }}</label>
                    </div>
                    <div class="input-box" v-bind:class="{'bg-gray': !selectedTicket.is_retain_CAS_type_editable}">
                        <multiselect 
                            :value="retainOptions.find(el => el.id == user.retain_CAS_CASS_type)"
                            class="select-tags-tbf ticket "
                            :options="retainOptions"
                            :allow-empty="true"
                            :show-labels="false"
                            track-by="id" 
                            label="name"
                            :disabled="!selectedTicket.is_retain_CAS_type_editable"
                            @input="user.retain_CAS_CASS_type = $event.id"
                            >
                            <template slot="placeholder" slot-scope="props">
                                <span class="text">
                                    {{ $t('tickets.ticket_type-ph') }}
                                </span>
                            </template>
                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                        </multiselect>
                    </div>
                </div>
                <div class="input-group w-10">
                    <div class="label-header">
                        <label v-if="index == 0" class="label">{{ $t('tickets.retain_cam') }}</label>
                    </div>
                    <div class="input-box" v-bind:class="{'bg-gray': !selectedTicket.is_retain_CAM_type_editable}">
                        <multiselect 
                            :value="retainOptions.find(el => el.id == user.retain_CAM_type)"
                            class="select-tags-tbf ticket "
                            :options="retainOptions"
                            :allow-empty="true"
                            :show-labels="false"
                            track-by="id" 
                            label="name"
                            :disabled="!selectedTicket.is_retain_CAM_type_editable"
                            @input="user.retain_CAM_type = $event.id"
                            >
                            <template slot="placeholder" slot-scope="props">
                                <span class="text">
                                    {{ $t('tickets.ticket_type-ph') }}
                                </span>
                            </template>
                            <template slot="noResult">{{ $t('create-user.no-results') }}</template>
                            <template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
                        </multiselect>
                    </div>
                </div>
            </div>
           <div v-else class="row-space-tbf row-list-item">
                <div class="content">
                    <div class="column-empty-list">
                        <div class="name">{{ $t('congratulations.empty-inline') }}</div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    <div class="form-submit crud">
        <!-- <div class="error-msg" v-show="error_message != ''">{{error_message}}</div> -->
        <button class="btn-tbf grey" @click="$router.push({ name: 'tickets' })"><span class="text">{{ $t('general.cancel') }}</span></button>
        <button id="buttonCreate" class="btn-tbf blue" @click="saveAction('create')" v-if="!$route.params.id">
            <div class="loader"></div>
            <span class="text">{{ $t('general.add') }}</span>
        </button>
        <button id="buttonUpdate" class="btn-tbf blue" @click="saveAction('update')" v-else>
            <div class="loader"></div>
            <span class="text">{{ $t('general.update') }}</span>
        </button>
    </div>
</div>
</template>

<script>
    import IconTicket from '../../../Icons/Ticket'
	import IconClose from '../../../Icons/Close'
    import IconArrowBack from '../../../Icons/ArrowLong'

    export default {
    	components: {
            IconArrowBack,
            IconTicket,
			IconClose
        },
        data() {
            return {
            	loaded: false,
				loadedList: false,
            	search_filter: '',
            	users: [],
            	sortBy: '',
            	sortDirection: 'asc',
				page: 0,
				infiniteId: 1,
				queryFiltersCount: 0,
            	extendFilters: false,

                tickets: {},
                retainOptions: [
                    {
                        id: 1,
                        name:"Retine total"
                    },
                    {
                        id: 2,
                        name:"Retine partial"
                    },
                    {
                        id: 3,
                        name:"Nu retine"
                    },
                ],
                selectedTicket: {
                    id: 1,
                    is_retain_CAM_type_editable: 0,
                    is_retain_CAS_type_editable: 0,
                    is_taxable_type_editable: 0,
                    name: "Tichete de masa",
                    retain_CAM_type: 3,
                    retain_CAS_CASS_type: 3,
                    taxable_type: 3
                }
            }
        },
        async mounted(){
            await this.getTicketsType()
            await this.getUsers()

			setTimeout(() => {
				var title = this.$t('salary-navbar.salary_state');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refresPayrollIndex', () => {
				this.getTicketsType()
			})
        },
		computed: {
        },
        
        methods: {
            async getTicketsType() {
                await axios.get('/ticket-types').then(({data}) => {
                   this.tickets = data.data
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
            },

            async getUsers() {
                await axios.get(`${this.$auth.user().instance.id}/payroll-tickets/create?ticket_type_id=${this.selectedTicket.id}`).then(({data}) => {
                   this.users = data.data
                }).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				}).finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
            },

           saveAction(type) {
				var buttonName = `button${ type == 'create' ? 'Create' : 'Update'}`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				// if (!$(".input-box.role").hasClass("has_error")){
					let objData = {}
                    objData.payroll_tickets = this.users.map(el => {
                        return{
                            id: el.id,
                            contract_id: el.contract_id,
                            month: el.month,
                            year: el.year,
                            ticket_type_id: el.ticket_type_id,
                            name: el.name,
                            number: el.number,
                            returned: el.returned,
                            nominal_value: el.nominal_value,
                            total_amount: Math.round(el.total_amount),
                            taxable_type: el.taxable_type,
                            retain_CAS_CASS_type: el.retain_CAS_CASS_type,
                            retain_CAM_type: el.retain_CAM_type
                        }
                    })

					if(type == 'create'){
						this.createTickets(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateTickets(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}

				// }else{
				// 	btnSubmitLoader.classList.add('finish')
				// 	setTimeout(()=>{
				// 		btnSubmitText.innerHTML = this.$t('btn-submit.error')
				// 		btnSubmit.classList.add('error')
				// 		btnSubmitLoader.classList.remove('onProgress', 'finish')
				// 		btnSubmit.classList.remove('loading')
				// 		setTimeout(()=>{
				// 			btnSubmit.classList.remove('error')
				// 			btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
				// 			btnSubmit.disabled = false
				// 		}, 1000)
				// 	}, 300)
				// }
			},
            createTickets(type, objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/payroll-tickets/store', objData)
				.then(({data}) => {
                    btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(() => {
							this.$router.push({ name: 'tickets' })
						}, 1000);
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
                            this.error_message = Object.values(error.response.data.errors).join('')
						}, 1000)
					}, 300)
				})
			},
			
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
        }
    }
</script>

<style lang="scss">
.text-name {
    font-size: 14px;
    height: 45px;
    align-items: center;
    display: flex;
    .circle-status{
        height: 10px;
        width: 10px;
        min-width: 10px;
        border-radius: 50%;
        background: #c5c5c5;
        border: 1px solid $secondary;
        margin-right: 7px;
        &.active{
            background: #6CE0A6;
            border: 1px solid #03AE58;
        }
    }
}
.multiselect{
    &.ticket {
        .multiselect__single {
            padding: 0 15px;
        }
        .multiselect__content-wrapper {
            width: 150px;
        }
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>